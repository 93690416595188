var exports = {};

exports = function isShallowEqual(a, b) {
  if (a === b) return true;

  for (var i in a) if (!(i in b)) return false;

  for (var i in b) if (a[i] !== b[i]) return false;

  return true;
};

export default exports;